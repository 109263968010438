import React from "react";
import { GoBack } from "../../../components/Layout/GoBack";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Refer = () => {
  const websiteURL = "https://www.varlinvestmenttrade.com/signup";

  const { user } = useSelector((state) => {
    const { user, token } = state.userData;

    return {
      user,
    };
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${websiteURL}${user.referralLink}`);

    toast.success("Referral link copied", {
      duration: 3000,
      position: "bottom-center",
    });
  };

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen">
        <GoBack />
        <h2 className="font-body font-Medium text-3xl mt-3">Refer</h2>

        <p className="text-sm font-body text-textGray mt-5">
          5% reward for any downliner that registers with your link
        </p>

        <div className="mt-4">
          <p className="text-fadedGray text-sm font-body font-light">
            Referral link:
          </p>
          <div onClick={() => copyToClipboard()} className="flex gap-x-2 ">
            <p className=" text-xs font-body ">
              {websiteURL}
              {user.referralLink}
            </p>
            <img className="" src="/img/icons/copy.svg" />
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-y-2">
          <div className="flex justify-between items-center">
            <p className="text-fadedGray text-sm font-body font-light">
              Referral Count
            </p>
            <p className="font-body text-md ">{user.referralCount}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
