import { CircularProgress } from "@mui/material";
import React from "react";

export const PrimaryButton = ({ text, loading, action, disabled }) => {
  return (
    <div
      onClick={disabled ? () => {} : action}
      className={`${
        disabled ? "bg-blue-200" : "bg-primary"
      } cursor-pointer font-fontMedium text-center text-white py-3 w-full rounded-md text-sm mx-auto transition-all duration-500`}
    >
      {loading ? (
        <CircularProgress size={20} style={{ color: "white" }} />
      ) : (
        text
      )}
    </div>
  );
};

export const SecondaryButton = ({ text, loading, action, disabled }) => {
  return (
    <div
      onClick={disabled ? () => {} : action}
      className={`${
        disabled ? "bg-[#D5F6EE]" : "bg-white"
      } cursor-pointer border border-[#CFCFCF] font-fontMedium text-center py-3 w-full rounded-md text-sm mx-auto transition-all duration-500`}
    >
      {loading ? (
        <CircularProgress size={20} style={{ color: "white" }} />
      ) : (
        text
      )}
    </div>
  );
};
