import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { PrimaryButton } from "../buttons";
import { InvestForm } from "../Forms/InvestForm";

export const Invest = ({ fromWallet, isHome }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 transition duration-500 "
      >
        <InvestForm
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </Modal>

      {fromWallet ? (
        <div
          onClick={handleOpen}
          className={`font-body text-xs  text-center font-light flex flex-col items-center justify-center gap-y-2 cursor-pointer`}
        >
          <img src="/img/icons/invest.svg"></img>
          <p className="font-body text-[#1E68D7]">Invest</p>
        </div>
      ) : (
        <div
          onClick={handleOpen}
          className="bg-primary rounded-lg text-center text-white text-sm mt-3 py-2 hover:border hover:border-primary hover:text-primary hover:bg-white transition-all duration-500 "
        >
          Invest
        </div>
      )}
    </>
  );
};
