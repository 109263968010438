import React from "react";
import { IoIosBriefcase, IoIosHome, IoIosSettings } from "react-icons/io";
import {
  NavLink,
  Link,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { RiBankCardFill } from "react-icons/ri";

export const BottomNav = () => {
  const pathname = useLocation().pathname;
  const navLinks = [
    {
      name: "home",
      Iconpath: "/img/icons/navhome.svg",
      Iconpathactive: "/img/icons/navhomeactive.svg",
    },
    {
      name: "transaction",
      Iconpath: "/img/icons/navtransaction.svg",
      Iconpathactive: "/img/icons/navtransactionactive.svg",
    },
    {
      name: "investment",
      Iconpath: "/img/icons/navinvestment.svg",
      Iconpathactive: "/img/icons/navinvestmentactive.svg",
    },
    {
      name: "setting",
      Iconpath: "/img/icons/navsettings.svg",
      Iconpathactive: "/img/icons/navsettingsactive.svg",
    },
  ];
  return (
    <div className="fixed mx-auto max-w-sm bg-white bottom-0 w-full py-2 shadow rounded-t-3xl border">
      <div className="grid grid-cols-5 justify-items-center mx-auto">
        {navLinks.map(({ name, Iconpath, Iconpathactive }, index) => (
          <NavLink
            key={index}
            to={`/dashboard/${name}`}
            activeClassName="text-primary"
            className="self-center font-body font-light"
          >
            <>
              {pathname === `/dashboard/${name}` ? (
                <div className="flex flex-col items-center">
                  <img src={Iconpathactive} />
                  <p className="text-xs font-fontbody">
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <img src={Iconpath} />
                  <p className="text-xs text-[#707070]">
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </p>
                </div>
              )}
            </>
          </NavLink>
        ))}
        <div></div>
      </div>
    </div>
  );
};

export const OnboardingNav = () => {
  const pathname = useLocation().pathname;
  const navLinks = [
    {
      name: "",
      Iconpath: "/img/new/icons/ellipse.svg",
      Iconpathactive: "/img/new/icons/rectangle.svg",
    },
    {
      name: "home2",
      Iconpath: "/img/new/icons/ellipse.svg",
      Iconpathactive: "/img/new/icons/rectangle.svg",
    },
    {
      name: "home3",
      Iconpath: "/img/new/icons/ellipse.svg",
      Iconpathactive: "/img/new/icons/rectangle.svg",
    },
  ];
  return (
    <div className="self-end flex gap-x-1">
      {navLinks.map(({ name, Iconpath, Iconpathactive }, index) => (
        <NavLink key={index} to={`/${name}`}>
          <>
            {pathname === `/${name}` ? (
              <img src={Iconpathactive} />
            ) : (
              <img src={Iconpath} />
            )}
          </>
        </NavLink>
      ))}
    </div>
  );
};
