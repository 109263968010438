import React from "react";
import { Invest } from "../Cards/Invest";
import { Deposite } from "../Deposite/Deposite";
import Withdraw from "../Withdraw/Withdraw";

export const Wallet = ({
  activeInvestment,
  expectedReturn,
  isHome,
  user,
  getUserInvestmentsLoading,
  investments,
}) => {
  return (
    <div>
      <div
        className={`relative bg-primary p-4 w-full  mx-auto text-white mt-6 rounded-lg grid grid-cols-2 gap-x-8`}
      >
        <div className="relative flex flex-col gap-y-4">
          <h2 className="font-fontLight text-sm font-fontregular text-center">
            Balance USD
          </h2>
          <div className="w-28 mx-auto border-4 py-6 border-white rounded flex items-center justify-center">
            {!getUserInvestmentsLoading && investments.length > 0 ? (
              <h3 className="font-body text-lg">
                {investments[0].profitProduction}%
              </h3>
            ) : (
              <h3 className="font-body text-lg">0%</h3>
            )}
          </div>
        </div>
        <div className="relative flex flex-col gap-y-2">
          <h3 className="font-fontRegular text-3xl">
            $ {user.balance ? user.balance : 0}
            <span className="text-lg">.00</span>
          </h3>
          <div className="flex flex-col font-fontLight">
            <h5 className="text-xs ">Active Invested</h5>
            <h5 className="text-xl ">${activeInvestment}</h5>
          </div>
          <div className="flex flex-col font-fontLight">
            <h5 className="text-xs ">Returns</h5>
            <h5 className="text-xl">${expectedReturn}</h5>
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-x-6 mt-5 px-2">
        <Deposite fromWallet={true} />
        <Withdraw fromWallet={true} />
        <Invest fromWallet={true} />
      </div>
    </div>
  );
};
