import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { PrimaryButton } from "../buttons";
import { toast } from "react-toastify";
import {
  clearCreateWithdrawSuccess,
  createWithdrawRequest,
} from "../../redux/action";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormError } from "../Error";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Withdraw = ({ isHome }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [amount, setAmount] = useState(0);

  const [error, setError] = useState("");

  const handleChange = (e) => {
    e = Number(e);
    if (e < 0) {
      setError("Enter a positive number");
    } else if (e > 10000000000) {
      setError("Invalid number");
    } else if (e < 10) {
      setError("minimum withdrawal is $10");
    } else if (e > user.balance) {
      setError("amount greater than user balance");
    } else {
      setError("");
      formik.setFieldValue("amount", e);
    }
  };

  const {
    user,
    createWithdrawLoading,
    createWithdrawSuccess,
    createWithdrawError,
  } = useSelector((state) => {
    const {
      success: { createWithdraw: createWithdrawSuccess },
      errors: { createWithdraw: createWithdrawError },
    } = state.ajaxStatuses;

    const { createWithdrawLoading } = state.loadingIndicator;

    const { user } = state.userData;

    return {
      user,
      createWithdrawLoading,
      createWithdrawSuccess,
      createWithdrawError,
    };
  });

  const formik = useFormik({
    initialValues: {
      type: "Withdrawal",
      amount: "",
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      prop.user = user._id;
      prop.email = user.email;
      prop.userName = user.userName;

      if (!error) {
        dispatch(createWithdrawRequest(prop));
      }
    },
  });

  useEffect(() => {
    if (createWithdrawError) {
      toast.error(createWithdrawError, {
        duration: 3000,
      });
    }
  }, [createWithdrawError]);

  useEffect(() => {
    if (createWithdrawSuccess) {
      toast.success(createWithdrawSuccess, {
        duration: 3000,
      });

      handleClose();

      dispatch(clearCreateWithdrawSuccess());
    }
  }, [createWithdrawSuccess]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 transition duration-500 "
      >
        <div className="h-[100vh] w-full bg-white outline-0 relative transition duration-500 overflow-y-scroll pb-8">
          <div className="container mx-auto max-w-sm pt-14 px-4 ">
            <div className="flex justify-between px-3">
              <img
                onClick={handleClose}
                className="cursor-pointer"
                src="/img/icons/arrowleft.svg"
              />

              <p
                onClick={handleClose}
                className="text-red-500 font-body text-sm cursor-pointer text-right"
              >
                Cancel
              </p>
            </div>

            <div className="pt-4">
              <h2 className="text-xl font-body font-bold ">Withdraw</h2>
              <h2 className="text-base font-body font-medium text-center mt-8">
                Crypto
              </h2>
              <img
                className="text-center mx-auto mt-2"
                src="/img/icons/ellipse.svg"
              />
            </div>

            <div className="mt-4 bg-blue-100 rounded-lg py-3 text-center flex flex-col gap-y-1">
              <p className="text-xs text-fadedGray font-body font-medium">
                Wallet balance
              </p>
              <h2 className="text-xl font-body font-medium ">
                {" "}
                ${user.balance}
              </h2>
              <p className="text-base text-fadedGray font-body font-light "></p>
            </div>

            <form className="max-w-xs mx-auto mt-8">
              <div className="flex flex-col gap-y-4 transition-all duration-500">
                <div className="flex flex-col gap-y-2">
                  <label className="text-xs font-body">Enter amount</label>
                  <input
                    className="border-b-2 bg-white w-full text-textGray  py-3 px-4 focus:outline-none focus:ring-0 focus:border-gray-100 focus:bg-white"
                    type={"number"}
                    onChange={(e) => {
                      setAmount(Number(e.target.value));
                      handleChange(e.target.value);
                    }}
                    placeholder="Enter amount to withdraw"
                    required
                  />
                </div>
                {error && <FormError message={error} />}
              </div>

              <div className="mt-4 flex flex-col gap-y-2">
                <div className="flex justify-between items-center">
                  <p className="font-body text-fadedGray text-sm">
                    Wallet Address
                  </p>

                  <div className="flex items-center gap-x-2">
                    {user.walletAddress ? (
                      <p className="font-body text-md ">
                        {" "}
                        {user.walletAddress.slice(0, 3)}****
                        {user.walletAddress.slice(
                          user.walletAddress.length - 3
                        )}
                      </p>
                    ) : (
                      <Link
                        to="/dashboard/profile"
                        className="font-body text-sm underline text-primary"
                      >
                        update wallet address
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-10 flex flex-col gap-y-6">
                <PrimaryButton
                  action={formik.handleSubmit}
                  loading={createWithdrawLoading}
                  text={"Confirm"}
                  disabled={amount <= 0}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <div
        onClick={handleOpen}
        className={`font-body text-xs  text-center font-light flex flex-col items-center justify-center gap-y-2 cursor-pointer`}
      >
        <img src="/img/icons/withdrawicon.svg"></img>
        <p className="font-body text-[#EA1300]">Withdraw</p>
      </div>
    </>
  );
};

export default Withdraw;
