import React from "react";
import { BulletPoints } from "./BulletPoints";
import { Invest } from "./Invest";

export const InvestCard1 = ({ plan, img }) => {
  return (
    <div className="text-black rounded-lg overflow-hidden font-body shadow">
      <img className="" src={img} />
      <div className="p-2">
        <div className="flex justify-between">
          <h2 className="text-lg">{plan.planName}</h2>

          <div className="flex items-center justify-center w-9 h-9 border border-black rounded-full text-xs ">
            {plan.profitProduction}%
          </div>
        </div>

        <p className="text-sm  ">
          Duration: <span className="text-primary">{plan.duration} day</span>
        </p>

        <p className="text-sm ">Min: ${plan.minAmount}</p>

        <p className="text-sm ">max: ${plan.maxAmount}</p>

        <Invest fromWallet={false} />
      </div>
    </div>
  );
};
