import React from "react";
import { GoBack } from "../../../components/Layout/GoBack";
import { BottomNav } from "../../../components/Nav/BottomNav";

const About = () => {
  return (
    <div>
      <div className="container px-4 pt-8 max-w-sm mx-auto relative  pb-24">
        <GoBack />

        {/* <div className="mt-5">
          <img className="rounded-lg" src="/img/new/background/cert.jpeg" />
        </div> */}
        <h2 className="font-body font-Medium text-3xl mt-3">About Us</h2>

        <p className="text-sm font-body text-textGray mt-5">
          ValrInvestmentTrade is a digital asset technology company, which
          engages in mining cryptocurrencies, with a focus on the blockchain
          ecosystem and the generation of digital assets.
        </p>

        {/* <div className="mt-3">
          <div className="flex gap-x-2">
            <h2 className="font-body font-bold text-base ">FOUNDER: </h2>{" "}
            <p className="text-base font-body text-textGray"> HAMID MOGHADAM</p>
          </div>
          <div className="flex gap-x-2">
            <h2 className="font-body font-bold text-base ">
              NUMBER OF EMPLOYEES:{" "}
            </h2>{" "}
            <p className="text-base font-body text-textGray"> 2,053</p>
          </div>
          <div className="flex gap-x-2">
            <h2 className="font-body font-bold text-base ">REVENUE: </h2>{" "}
            <p className="text-base font-body text-textGray">
              {" "}
              5.97$ BILLION(2022)
            </p>
          </div>
          <div className="flex gap-x-2">
            <h2 className="font-body font-bold text-base ">HEADQUARTERED: </h2>{" "}
            <p className="text-base font-body text-textGray"> SAN FRANCISCO</p>
          </div>
          <div className="flex gap-x-2">
            <h2 className="font-body font-bold text-base ">FOUNDED: </h2>{" "}
            <p className="text-base font-body text-textGray">
              {" "}
              1983 AND JOINED CRYPTO MARKETING IN 2011.
            </p>
          </div>
          <div className="flex gap-x-2">
            <h2 className="font-body font-bold text-base ">OWNERS:</h2>{" "}
            <p className="text-base font-body text-textGray">
              {" "}
              THE VANGUARD GROUP,BLACKROCK.
            </p>
          </div>
        </div> */}

        {/* <h2 className="font-body font-bold text-2xl mt-5">Testimonies</h2> */}

        {/* <div className="flex flex-col gap-y-4">
          <img className="rounded-lg" src="/img/new/background/test1.jpeg" />
          <img className="rounded-lg" src="/img/new/background/test2.jpeg" />
          <img className="rounded-lg" src="/img/new/background/test3.jpeg" />
        </div> */}

        <div className="mt-5">
          <p className="text-xs font-body text-textGray">
            Company headquarters @
          </p>
          <p className="text-xs font-body text-textGray">Johannesburg</p>
          <p className="text-xs font-body text-textGray">South Africa</p>
          <p className="text-xs font-body text-textGray">+27 62 069 9224</p>
        </div>
      </div>
      <div className=" max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default About;
