import React from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import { OnboardingNav } from "../../../components/Nav/BottomNav";
import { Onboarding } from "../../../components/Sections/Onboarding";

const Home1 = () => {
  return (
    <div>
      <Onboarding
        linkto={"/signup"}
        heading={"Future Of Digital Asset"}
        body={
          "ValrInvestmentTrade is a digital asset technology company, which engages in mining cryptocurrencies, with a focus on the blockchain ecosystem and the generation of digital assets."
        }
        imgurl={"/img/new/background/bit.jpg"}
        next={"/home2"}
      />
    </div>
  );
};

export default Home1;
