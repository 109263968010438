import React from "react";
import { OnboardingNav } from "../Nav/BottomNav";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../buttons";

export const Onboarding = ({ linkto, heading, body, imgurl, next }) => {
  return (
    <div className="container px-4 py-8 max-w-sm mx-auto relative h-[90vh] font-body flex flex-col">
      <div className="flex justify-between">
        <img className="w-48" src="/img/new/logo/logo.png" />
      </div>

      <h3 className="text-xl mt-4 font-fontRegular">{heading}</h3>

      <div>
        <img className="mt-4 rounded-lg" src={imgurl} />
      </div>

      <p className="font-light mt-3 font-fontLight">{body}</p>

      <div className="flex gap-x-4 justify-between items-center bottom-4 absolute w-[92%] mx-auto">
        <Link to={"/signup"} className="w-full">
          <PrimaryButton text="Get Started" />
        </Link>
        <Link to={"/signin"} className="w-full">
          <SecondaryButton text="Sign in" />
        </Link>
      </div>
    </div>
  );
};
