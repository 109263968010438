import React from "react";
import { GoBack } from "../../../components/Layout/GoBack";
import { BottomNav } from "../../../components/Nav/BottomNav";

const Plandetails = () => {
  return (
    <div>
      <div className="container px-4 pt-8 max-w-sm mx-auto relative  pb-24">
        <GoBack />

        <div className="mt-5">
          {/* <img
            className="rounded-lg"
            src="/img/new/background/warehouse.jpeg"
          /> */}
        </div>
        <h2 className="font-body font-bold text-xl mt-3">About Our Plans</h2>

        <p className="text-sm font-body text-textGray mt-5">
          Basic $20-$299 : 5%{" "}
        </p>
        <p className="text-sm font-body text-textGray mt-2">
          Hero $300-$2999 : 7%
        </p>
        <p className="text-sm font-body text-textGray mt-2">
          Expert $3000-$4999 : 9%
        </p>
        <p className="text-sm font-body text-textGray mt-2">
          Super $5000-$1000000: 18%
        </p>

        <div className="mt-3">
          <p className="font-body  text-base lowercase ">
            MINIMUM DEPOSIT: 20$
          </p>

          <p className="font-body  text-base lowercase ">
            MINIMUM WITHDRAWAL:10$
          </p>

          <p className="font-body  text-base lowercase ">
            DAILY EARNING AND WITHDRAWAL
          </p>

          <p className="font-body  text-base lowercase ">
            INVESTMENT DURATION: 24 HOURS
          </p>

          <p className="font-body  text-base lowercase ">
            WITHDRAWAL TIMING: 5 MINUTES
          </p>

          <p className="font-body  text-base lowercase ">WELCOME BONUS: 3$</p>

          <p className="font-body  text-base lowercase ">LIFE-TERM ASSURED</p>
          <p className="font-body  text-base lowercase ">TEAM LEADER BONUS</p>
          <p className="font-body  text-base lowercase ">FREE GIFTS</p>
          <p className="font-body  text-base lowercase ">
            FREE VISA FOR VACATION TO ANY COUNTRY
          </p>
          <p className="font-body  text-base lowercase ">NO WITHDRAWAL FEE</p>
          <p className="font-body  text-base lowercase ">
            24 HOURS ACTIVE SUPPORT AND WHATSAPP CUSTOMER CARE
          </p>
        </div>
      </div>
      <div className=" max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Plandetails;
