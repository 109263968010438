import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AnimatePresence } from "framer-motion";
import { Page1 } from "./Page1";
import { Page2 } from "./Page2";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  clearCreatePaymentSuccess,
  createPaymentRequest,
} from "../../redux/action";
import { toast } from "react-toastify";

export const Deposite = ({ isHome }) => {
  const [pages, setPages] = useState(1);
  const [x, setX] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedToken, setSelectedToken] = useState({
    tokenName: "",
    logopath: "",
    address: "",
    network: "",
  });

  const [amount, setAmount] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const data = location.state;

  const {
    user,
    createPaymentLoading,
    createPaymentSuccess,
    createPaymentError,
  } = useSelector((state) => {
    const {
      success: { createPayment: createPaymentSuccess },
      errors: { createPayment: createPaymentError },
    } = state.ajaxStatuses;

    const { createPaymentLoading } = state.loadingIndicator;

    const { user } = state.userData;

    return {
      user,
      createPaymentLoading,
      createPaymentSuccess,
      createPaymentError,
    };
  });

  const formik = useFormik({
    initialValues: {
      type: "Deposite",
      fundAmount: "",
      imgReceiptUrl: "",
      transactionRef: "",
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      console.log(prop.imgReceiptUrl);
      if (prop.imgReceiptUrl === "" && prop.transactionRef === "") {
        toast.error("Upload a receipt or enter ref", {
          duration: 3000,
        });
      } else {
        prop.user = user._id;
        prop.email = user.email;
        prop.userName = user.userName;
        prop.fundAmount = amount;

        console.log(prop);

        dispatch(createPaymentRequest(prop));
      }
    },
  });

  useEffect(() => {
    if (createPaymentError) {
      toast.error(createPaymentError, {
        duration: 3000,
      });
    }
  }, [createPaymentError]);

  useEffect(() => {
    if (createPaymentSuccess) {
      toast.success(createPaymentSuccess, {
        duration: 3000,
      });
      handleClose();
      dispatch(clearCreatePaymentSuccess());
    }
  }, [createPaymentSuccess]);

  const componentArray = [
    <Page1
      handleClose={handleClose}
      pages={pages}
      setPages={setPages}
      x={x}
      setX={setX}
      selectedToken={selectedToken}
      setSelectedToken={setSelectedToken}
      amount={amount}
      setAmount={setAmount}
      formik={formik}
    />,
    <Page2
      handleClose={handleClose}
      pages={pages}
      setPages={setPages}
      x={x}
      setX={setX}
      selectedToken={selectedToken}
      amount={amount}
      setAmount={setAmount}
      formik={formik}
      createPaymentLoading={createPaymentLoading}
    />,
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 transition duration-500 "
      >
        <div className="h-[100vh] w-full bg-white outline-0 relative transition duration-500 overflow-y-scroll pb-8">
          <AnimatePresence>{componentArray[pages - 1]}</AnimatePresence>
        </div>
      </Modal>
      <div
        onClick={handleOpen}
        className={`font-body text-xs  text-center font-light flex flex-col items-center justify-center gap-y-2 cursor-pointer`}
      >
        <img src="/img/icons/add.svg"></img>
        <p className="font-body text-[#12CA70]">Fund</p>
      </div>
    </>
  );
};
