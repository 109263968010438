import React, { useState, useEffect } from "react";
import { FormError } from "../../../components/Error";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  registerRequest,
  clearRegisterSuccess,
  getUserRequest,
} from "../../../redux/action";
import { GrFormClose } from "react-icons/gr";
import { PrimaryButton } from "../../../components/buttons";
import { GoBack } from "../../../components/Layout/GoBack";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = () => {
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [userRef, setUserRef] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getUserLoading,
    getUserError,
    registerSuccess,
    registerError,
    registerLoading,
    user,
    singleUser,
  } = useSelector((state) => {
    const {
      success: { register: registerSuccess },
      errors: { getUser: getUserError, register: registerError },
    } = state.ajaxStatuses;

    const { registerLoading, getUserLoading } = state.loadingIndicator;

    const { user, singleUser } = state.userData;

    return {
      getUserLoading,
      getUserError,
      registerSuccess,
      registerError,
      registerLoading,
      user,
      singleUser,
    };
  });

  let query = useQuery();

  const formik = useFormik({
    initialValues: {
      userName: "",
      phone: "",
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      userName: yup
        .string()
        .required("username is required")
        .max(32, "username is too long")
        .trim()
        .matches(/^[a-zA-Z]+$/, "username must be alphabetic without spacing"),

      phone: yup.string().required("Phone is required"),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),

    onSubmit: (prop) => {
      if (prop.password !== confirmPassword) {
        toast.error("Password does not match");
      } else {
        // let encrypted = CryptoJS.AES.encrypt(
        //   JSON.stringify(prop.password),
        //   "my-secret-key@123"
        // ).toString();
        // prop.investmentDetail = encrypted;

        console.log(prop);
        let formData = prop;
        let refId = query.get("refId");

        dispatch(registerRequest({ formData, refId }));
      }
    },
  });

  useEffect(() => {
    if (registerError) {
      toast.error(registerError, {
        duration: 3000,
      });
    }
  }, [registerError]);

  useEffect(() => {
    if (registerSuccess) {
      // toast.success(registerSuccess, {
      //   duration: 3000,
      // });

      history.push("/dashboard/home");

      dispatch(clearRegisterSuccess());
    }
  }, [registerSuccess]);

  useEffect(() => {
    let refId = query.get("refId");
    if (refId) {
      setUserRef(refId);
      dispatch(getUserRequest(refId));
    }
  }, [getUserRequest]);

  useEffect(() => {
    if (getUserError) {
      setUserRef("");
    }
  }, [getUserError]);

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen overflow-y-scroll pb-16 font-body">
        <div className="flex justify-between px-4">
          <GoBack />
        </div>

        <div className="flex flex-col gap-y-2 px-4 mt-6 ">
          <h1 className="font-fontMedium text-[22px] text-left">
            Create your account
          </h1>
          <p className="text-textGray text-left text-xs">
            Open an ValrInvestmentTrade account with a few details.
          </p>
        </div>

        <form className="max-w-xs mx-auto mt-8">
          <div className="flex flex-col gap-y-4">
            {userRef && (
              <div className="flex flex-col gap-y-2">
                <label className="text-xs font-body">Referred by</label>
                <input
                  className="bg-formGray text-textGray w-full text-sm rounded border border-[#CFCFCF] py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#CFCFCF] focus:bg-formGray"
                  type={"text"}
                  value={singleUser.userName}
                  placeholder="Referred user"
                  required
                />
              </div>
            )}
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">User Name</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border border-[#CFCFCF] py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#CFCFCF] focus:bg-formGray"
                type={"text"}
                {...formik.getFieldProps("userName")}
                placeholder="Your username"
                required
              />
              {formik.errors.userName && formik.touched.userName && (
                <FormError message={formik.errors.userName} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Email Address</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border border-[#CFCFCF] py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#CFCFCF] focus:bg-formGray"
                type={"email"}
                {...formik.getFieldProps("email")}
                placeholder="Your email address"
                required
              />
              {formik.errors.email && formik.touched.email && (
                <FormError message={formik.errors.email} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Phone</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border border-[#CFCFCF] py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#CFCFCF] focus:bg-formGray"
                type="number"
                {...formik.getFieldProps("phone")}
                placeholder="Your phone number"
                required
              />
              {formik.errors.phone && formik.touched.phone && (
                <FormError message={formik.errors.phone} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Password</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border border-[#CFCFCF] py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#CFCFCF] focus:bg-formGray"
                type={"password"}
                {...formik.getFieldProps("password")}
                placeholder="Create a strong password"
                required
              />
              {formik.errors.password && formik.touched.password && (
                <FormError message={formik.errors.password} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Confirm Password</label>
              <input
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="bg-formGray text-textGray w-full text-sm rounded border border-[#CFCFCF] py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#CFCFCF] focus:bg-formGray"
                type={"password"}
                placeholder="Confirm Password"
                required
              />
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-y-6">
            <PrimaryButton
              text={"Sign Up"}
              action={formik.handleSubmit}
              loading={registerLoading}
            />

            <Link to="/signin">
              <p className="font-body text-sm text-center text-fadedGray">
                Do you already have a ValrInvestmentTrade account?
                <span className="text-primary"> Login</span>
              </p>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
