import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../buttons";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FormError } from "../Error";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvestmentRequest,
  clearCreateInvestmentSuccess,
} from "../../redux/action";

export const InvestForm = ({ open, setOpen, handleClose, handleOpen }) => {
  const dispatch = useDispatch();

  const [payout, setPayout] = useState(0);
  const [payout5, setPayout5] = useState(0);
  const [newPlan, setPlan] = useState("");
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState(0);

  const handleChange = (e) => {
    e = Number(e);
    if (e < 0) {
      setError("Enter a positive number");
      setPayout(0);
      setPlan("");
    } else if (e > 0 && e < 20) {
      setError("Min Investment is $20");
      setPayout(0);
      setPlan("");
    } else if (e > 10000000000) {
      setError("Invalid number");
      setPayout(0);
      setPlan("");
    } else if (e > Number(user.balance)) {
      setError("Amount greater than users balance");
      setPayout(0);
      setPlan("");
    } else {
      setError("");
      if (e >= 20 && e < 299) {
        setPercent(5);
        setPlan("Basic");
        let profit = e * 0.05;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        formik.setFieldValue("amount", e);
      } else if (e >= 300 && e < 2999) {
        setPercent(7);
        setPlan("Hero");
        let profit = e * 0.07;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        formik.setFieldValue("amount", e);
      } else if (e >= 3000 && e < 4999) {
        setPercent(9);
        setPlan("Expert");
        let profit = e * 0.09;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        formik.setFieldValue("amount", e);
      } else if (e >= 5000 && e < 10000000) {
        setPercent(18);
        setPlan("Super");
        let profit = e * 0.18;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        formik.setFieldValue("amount", e);
      } else {
        setPayout(0);
      }
    }
  };

  const {
    createInvestmentSuccess,
    createInvestmentError,
    createInvestmentLoading,
    user,
    plans,
    investment,
  } = useSelector((state) => {
    const {
      success: { createInvestment: createInvestmentSuccess },
      errors: { createInvestment: createInvestmentError },
    } = state.ajaxStatuses;

    const { createInvestmentLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { plans } = state.planData;
    const { investment } = state.investmentData;

    return {
      createInvestmentSuccess,
      createInvestmentError,
      createInvestmentLoading,
      user,
      plans,
      investment,
    };
  });

  const formik = useFormik({
    initialValues: {
      user: "",
      amount: "",
      expectedReturn: "",
      profitProduction: 0,
      planName: "",
    },
    validationSchema: yup.object({
      amount: yup.string().required("Amount is required"),
    }),

    onSubmit: (prop) => {
      prop.user = user._id;
      prop.expectedReturn = prop.amount;
      prop.profitProduction = percent;
      prop.planName = newPlan;

      if (error) {
        toast.error("Invalid Number", {
          duration: 3000,
        });
      } else {
        dispatch(createInvestmentRequest(prop));
      }
    },
  });

  useEffect(() => {
    if (createInvestmentError) {
      toast.error(createInvestmentError, {
        duration: 3000,
      });
    }
  }, [createInvestmentError]);

  useEffect(() => {
    if (createInvestmentSuccess) {
      toast.success(createInvestmentSuccess, {
        duration: 3000,
      });

      handleClose();

      dispatch(clearCreateInvestmentSuccess());
    }
  }, [createInvestmentSuccess]);

  return (
    <div className="h-[100vh] w-full bg-white outline-0 relative transition duration-500 overflow-y-scroll pb-8">
      <div className="container mx-auto max-w-sm pt-14 px-4 ">
        <div className="flex justify-between px-3">
          <img
            onClick={handleClose}
            className="cursor-pointer"
            src="/img/icons/arrowleft.svg"
          />

          <p
            onClick={handleClose}
            className="text-red-500 font-body text-sm cursor-pointer text-right"
          >
            Cancel
          </p>
        </div>

        <div className="pt-4">
          <h2 className="text-xl font-body font-bold ">Invest</h2>
          <h2 className="text-base font-body font-medium text-center mt-8">
            Crypto
          </h2>
          <img
            className="text-center mx-auto mt-2"
            src="/img/icons/ellipse.svg"
          />
        </div>

        <div className="mt-4 bg-blue-100 rounded-lg py-3 text-center flex flex-col gap-y-1">
          <p className="text-xs text-fadedGray font-body font-medium">
            Wallet balance
          </p>
          <h2 className="text-xl font-body font-medium ">${user.balance}</h2>
          <p className="text-base text-fadedGray font-body font-light "></p>
        </div>

        <form className="max-w-xs mx-auto mt-8">
          <div className="flex flex-col gap-y-4 transition-all duration-500">
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Enter amount</label>
              <input
                className="border-b-2 bg-white w-full text-textGray  py-3 px-4 focus:outline-none focus:ring-0 focus:border-gray-100 focus:bg-white"
                type={"number"}
                onChange={(e) => {
                  handleChange(e.target.value);
                  setAmount(Number(e.target.value));
                }}
                placeholder="Enter amount to invest"
                required
              />
              {error && <FormError message={error} />}
            </div>
          </div>

          <div className="mt-4 flex flex-col gap-y-2">
            <div className="flex justify-between items-center">
              <p className="font-body text-fadedGray text-sm">Plan</p>
              <p className="font-body text-md ">{newPlan}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="font-body text-fadedGray text-sm">
                Return in day 1
              </p>
              <p className="font-body text-md ">${payout}</p>
            </div>
            {/* <div className="flex justify-between items-center">
              <p className="font-body text-fadedGray text-sm">
                Return in day 5
              </p>
              <p className="font-body text-md ">${payout5}</p>
            </div> */}
          </div>

          <div className="mt-10 flex flex-col gap-y-6">
            <PrimaryButton
              loading={createInvestmentLoading}
              text={"Invest"}
              action={formik.handleSubmit}
              disabled={amount < 20 || error}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
