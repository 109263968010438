import React from "react";
import { BsPeople, BsWhatsapp } from "react-icons/bs";
import {
  IoMdPeople,
  IoMdSettings,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { FiGift } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const SideNav = () => {
  const sideNavContent = [
    {
      navName: "My Profile",
      NavImg: CgProfile,
      navContent: "Your profile and personal information",
      navLink: "/dashboard/profile",
    },
    {
      navName: "Security",
      NavImg: IoMdSettings,
      navContent: "Manage how you access your account",
      navLink: "/dashboard/security",
    },
    {
      navName: "About US",
      NavImg: IoMdInformationCircleOutline,
      navContent: "Read about ValrInvestmentTrade",
      navLink: "/dashboard/aboutus",
    },
    {
      navName: "Plan Details",
      NavImg: FiGift,
      navContent: "Read about ValrInvestmentTrade",
      navLink: "/dashboard/aboutplans",
    },
  ];

  return (
    <div className="flex flex-col mt-6 gap-y-3 pb-20">
      {sideNavContent.map(({ navName, navContent, NavImg, navLink }) => (
        <Link to={navLink} className="flex gap-x-3 border rounded-lg p-4">
          <NavImg className="text-xl" />
          <div className="">
            <h4 className="text-sm font-body">{navName}</h4>
          </div>
        </Link>
      ))}
      <Link
        to="/dashboard/refer"
        className="flex gap-x-3 border rounded-lg p-4"
      >
        <BsPeople className="text-xl  " />
        <div className="">
          <h4 className="text-sm font-body">Refer</h4>
        </div>
      </Link>
      {/* <a
        href="https://wa.me/message/UMJ6ZEKB2FIZH1"
        className="flex gap-x-3 border rounded-lg p-4"
      >
        <div className="bg-formGray p-2.5 rounded-xl">
          <BsWhatsapp className="text-2xl  rounded-xl" />
        </div>

        <div className="">
          <h4 className="text-sm font-body">Contact Us</h4>
          <p className="text-xs text-fadedGray font-body mt-1">
            Get in touch on Whatsapp
          </p>
        </div>
      </a> */}
    </div>
  );
};
